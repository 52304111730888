.o-scroll {
    overflow-x: hidden;
        .has-scroll-smooth & {

        min-height: 100vh;
        will-change: transform, opacity;
    
    
    }

}
