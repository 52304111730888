.c-job-combo {
  &__content {
    position: relative;
    display: block;
    @media(max-width: $to-medium) {
      background-color: $light-bg;
    }
    padding: rem(100px) 0;
    

    @media(min-width: $from-medium) {
      padding-top: rem(500px);
      padding-bottom: rem(120px);  

      &::before {
        content: '';
        display: block;
        background-color: $light-bg;
        position: absolute;
        top: 0;
        right: 0;
        left: -9rem;
        width: calc(100% + 9rem);
        height: 100%;
        z-index: -1;
      }
    }

    @media(min-width: $from-large) {
      padding-top: rem(400px);
    }

    a.o-button{
      z-index: 999;
    }

    
  }

  &-slider {
    position: relative;
    background: $accent-2;
    @media(min-width: $from-medium) {
      margin-top: rem(200px);

    }

    &::after {
      content: '';
      display: block;
      background-image: url(../../assets/images/gooe.svg);
      width: rem(400px);
      height: rem(100px);
      position: absolute;
      background-repeat: no-repeat;
      background-size: contain;
      right: 20%;
      z-index: 1;
      // bottom: -98px
      
    }

    @media(min-width:$from-medium) {
      border-radius: 0 5px 5px 0;
    }



    &__main {
      
    }

    &__content {
      text-align: left;
     padding: rem(60px) rem(20px);
      @media(min-width:$from-medium) {
        padding: rem(60px) rem(30px);
        margin-left: rem(60px);
      }

      h3 {
        font-size: rem(41px);
        margin-bottom: 0;
      }
      
    }
  }

  .swiper-pagination {

    .swiper-pagination-bullet-active {
      background: $color;

    }
  }

  .swiper-control {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    &-options {
      display: flex;
    align-items: center;
    background: $accent;

    border-radius: 5px 0 0 0;

    @media(min-width: $from-medium) {
      border-radius: 5px 0 5px 0;

    }
      svg {
        fill: #fff;
        width: rem(20px);
        height: rem(30px);
        margin-left: rem(10px);
        
      }
      a,button {
        display: block;
        margin: 0 rem(10px);
        color: #fff;
        text-transform: uppercase;
        font-weight: bold;
        font-size: rem(12px);
        display: flex;
        align-items: center;
        &:hover {
          cursor: pointer;
        }

        &.active {
          svg {
            fill: $accent-2
          }
        }
      }
    }
  }
}