.c-benefits {
  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -99;

  }

  &__inner {
    .o-container {
      @media(max-width: $to-small) {
        padding: 0;
        .c-benefits__banner {
          border-radius: 0;
          padding: rem(60px);
        }
      }
    }
  }

  &__banner {
    background-color: #ffb71c;
    padding: rem(60px);
    border-radius: 5px;
    position: relative;
    margin-top: rem(-90px);
    z-index: 99;


    @media(min-width: $from-medium) {


      &::before {
        content: '';
        display: block;
        position: absolute;
        background-image: url(../../assets/images/McD_SesameSeeds-final.gif);
        background-size: contain;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        z-index: -1;
        top: 0;
        left: 0;
        background-repeat: no-repeat;
        background-position: -30% bottom;
      }
    }
  }

}


.c-benefits-list-scroll {
  position: relative;

  &__layout {
    @media (max-width: $to-medium) {
      flex-flow: column;

      &.-mobile-flip {
        flex-flow: column-reverse;
      }
    }

    .o-container {
      padding-right: 0;
    }
  }

  &__inner {
    position: relative;
    padding: rem(90px) 0;

    @media(min-width: $from-medium) {

      display: flex;
      align-items: center;
    }


  }

  &__background {

    &.no-bg {
      text-align: center;
      // right: 3rem;
      height: 100%;
      margin-bottom: rem(60px);

      .o-background {
        position: relative;
      }

      svg {
        height: rem(200px);
      }
    }

    position: relative;
    overflow: hidden;


    height: 15rem;

    // &::after {
    //   content: '';
    //   position: absolute;
    //   bottom: 0;
    //   z-index: -1;
    //   border-radius: 5px 0 0 5px;
    //   right: 0;
    //   width: 95%;
    //   height: 90%;
    //   background-color: #f3f3f3;

    //   @media(min-width: $from-enormous) {
    //     width: 70%;
    //   }
    // }



    @media (min-width: $from-small) {

      height: rem(360px);
    }

    @media (min-width: $from-medium) {
      height: rem(500px);

      [data-namespace="interior"] & {
        height: rem(560px);
      }
    }

    svg {

      fill: $accent-2;
      width: rem(200px);
      height: 100%;
      @media(min-width: $from-medium) {
        width: rem(300px);

      }
    }

    &--text {
      font-size: 2rem;
    color: $accent-3;
    font-weight: bold;
    // right: 3rem;
    // padding: 0rem 5rem;
    // padding-right: 5rem;
    position: relative;
    // width: calc(100% + 9rem);
    text-align: center;


    // font-size: 2rem;
    // background: #fff;
    // color: #292929;
    // font-weight: bold;
    // left: -6rem;
    // border-radius: 10px;
    // position: absolute;
    // padding: 2rem 5rem;
    
    // width: calc(100% + 3rem);
    // text-align: center;
    // bottom: 0rem;
    // z-index: -1;

    &.mod {
      background-color: $accent-2;
      color: $accent-3;
      border-radius: 5px 0 0 5px;
      padding: 1rem;
      margin-top: -3rem;
    }
    }

  }

  &__cta {
    width: calc(100% + 14vw);
    left: -7vw;

    @media (min-width: $from-tiny) {
      width: calc(100% + 20vw);
      left: -10vw;
    }

    @media (min-width: $from-small) {
      width: auto;
      left: auto;
      margin-top: -4rem;
    }

    background-color: $bg;
    z-index: 1;
    position: relative;
    color: #fff;
    padding: rem(60px);

    p {
      font-size: rem(21px);
      font-weight: 600;
    }
  }

  &__list {
    padding: rem(30px) 0;

    @media(min-width: $from-medium) {
      width: 80%;
      max-width: 100%;

    }

    &-item {
      margin-bottom: rem(30px);

      @media(min-width: $from-small) {
        display: flex;
        align-items: center;

      }



    }

    &-img {
      width: 60px;
      margin-bottom: rem(30px);
    }

    &-content {
      font-weight: bold;

      @media(min-width: $from-small) {
        margin-left: rem(30px);
      }

      h3 {
        color: $accent;
        font-weight: bold;
        margin-bottom: rem(10px);
      }
    }
  }

  &__main {
    // height: 500px;
    // overflow-x: scroll;
    position: relative;
    display: block;
  }

  &__item {
    display: flex;
    align-items: center;

  margin-bottom: rem(60px);
    cursor: pointer;
    height: auto;

    &-img {
      background-color: #fff;
      border-radius: 10px;
      padding: rem(10px);
      border: $accent-2 solid 3px;
      margin-right: rem(20px);
      position: relative;

      @media(min-width: $from-medium) {

        .active & {
          &::before {
            content: '';
            display: block;
            top: -11px;
            right: -8px;
            background-color: $accent;
            width: rem(15px);
            position: absolute;
            border-radius: 50%;
            height: rem(15px);
          }
        }
      }


      svg,img {
        width: rem(50px);
        height: rem(50px);
        fill: $accent-2
      }
    }

    &-content {
      color: $accent-3;
      h4 {
        font-size: 1.3rem;
      }


      p {
        margin: 0 auto;
        display: block;

        @media(min-width: $from-medium) {
          display: none;
        }
      }
    }

    &-wall {
      display: none;

      &.active {
        display: block;
        animation-name: fadeIn;
        animation-duration: 400ms;
        animation-timing-function: ease-in-out;
        animation-iteration-count: 1;
      }

      .no-bg {
        &::after {
          content: none;
        }
      }

      &.push {
        padding-right: 9rem;
      }


    }
  }

  &__nav {
    @media(min-width: $from-medium) {
      width: 50%;
    }

    &-wrapper {

      @media(min-width: $from-medium) {
        height: 500px;
        overflow-x: auto;
        position: relative;
        display: block;
        padding-top: rem(30px);
        
        scrollbar-color: $accent $gray-3 !important;
        scrollbar-width: thin !important;

  
        /* width */
        &::-webkit-scrollbar {
          width: 5px;
        }
  
        /* Track */
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px gray;
          border-radius: 5px;
        }
  
        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: $accent;
          border-radius: 5px;
          
          
        }
  
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #b30000;
         
        }
      }

      
    }

    &-pagination {
      text-align: center;
      @media(min-width: $from-medium) {
        display: none;
      }
      .swiper-pagination-bullet-active {
        background-color: $accent;
      }
    }
  }

  &__content {
    display: none;
    @media(min-width: $from-medium) {
      width: 50%;
      right: -9rem;
      position: relative;
      display: block;
    }
   
  }

  .o-background__image-full {
    background-size: contain;
    background-repeat: no-repeat;
    &.zoom {
      margin-bottom: -30px;
      margin-top: -30px;

      
    }
  }

  .safety-img {
    background-size: cover;
    @media(min-width:$from-huge) {
      background-position: right -50px;
      // background-size: contain;
    }
    @media(min-width: $from-colossal) {
      background-size: contain;
    }
    @media(max-width: $to-large) {
      background-position: center 30px !important;
    }
  }




}







@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(2px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(2px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}


