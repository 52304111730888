// ==========================================================================
// Utilities / States
// ==========================================================================

/**
 * ARIA roles display visual cursor hints
 */
[aria-busy="true"] {
    cursor: progress;
}

[aria-controls] {
    cursor: pointer;
}

[aria-disabled] {
    cursor: default;
}

/**
 * Control visibility without affecting flow.
 */

.is-visible {
    visibility: visible !important;
    opacity: 1 !important;
}

.is-invisible {
    visibility: hidden !important;
    opacity: 0 !important;
}

/**
 * Completely remove from the flow and screen readers.
 */

.is-hidden {
    @include u-hidden;
}

@media not print {
    .is-hidden\@screen {
        @include u-hidden;
    }
}

@media print {
    .is-hidden\@print {
        @include u-hidden;
    }
}

// .is-hidden\@to-large {
//     @media (max-width: $to-large) {
//         display: none;
//     }
// }

// .is-hidden\@from-large {
//     @media (min-width: $from-large) {
//         display: none;
//     }
// }

// /**
//  * Display a hidden-by-default element.
//  */

// .is-shown {
//     @include u-shown;
// }

// table.is-shown {
//     display: table !important;
// }

// tr.is-shown {
//     display: table-row !important;
// }

// td.is-shown,
// th.is-shown {
//     display: table-cell !important;
// }


.is-loaded {
    &.has-scrolled {
      .c-header-navigation__nav,
      .c-header-navigation__logo,
      .c-main-hero__social,
      .c-header-navigation__search-btn,
      .__scroll {
        opacity: 0;
        visibility: hidden;
        transition: 0.5s;
        transform: translateY(-30px);
      }
      .c-header-navigation__content {
       background-color: transparent;
       
      }
  
      .c-header-navigation__content::before {
        opacity: 0;
        transition: 0.5s;
      }
  
      .c-header-navigation__button-line {
        background-color: #000;
      }
  
      .c-header-navigation__menu {
        @media (min-width: $from-tiny) {
          background-color: $accent-2;
        }
      }
  
      .c-header-navigation__button::before {
        opacity: 1;
        color: #000;
      }
    }
  
    &.has-nav {
      .o-line {
        opacity: 0;
      }
    }
  
    &.has-scrolled-bottom {
      .__back-to {
        opacity: 1;
        transform: translateY(-30px);
      }
      .o-line {
        opacity: 0.5;
      }
    }
  
    .c-header-navigation__logo,
    .c-header-navigation__nav,
    .c-header-navigation__content,
    .c-header-navigation__menu,
    .c-header-navigation__search-btn,
    .__back-to,
    .c-main-hero__social,
    .__scroll {
      transition: background-color 0.6s linear,
        opacity 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.3s,
        transform 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.3s;
    }
  }
  
  .is-loaded {
    &.has-scrolled {
      .has-nav-open {
        .c-header-navigation__button-line {
          background-color: #000;
        }
        .c-header-navigation__button::before {
          color: #000;
        }
      }
    }
    .has-nav-open {
      .c-header-navigation__button-line {
        background-color: #000;
      }}
  }
  