[data-namespace="interior"] {
  .c-main-hero__background {
    width: 100%;
    height: calc(50% - 3rem);
    top: initial;
    bottom: 0;

    @media(min-width: $from-medium) {
      width: 55%;
      right: 0;
      left: initial;
      height: 100%;
      height: calc(100% - 12rem);
    }
    @media(min-width: $from-huge) {
      width: 40%;
    }

    .o-background {
      background-position: bottom right;
    background-size: contain;
    }

  
    
  }

  .c-main-hero {

    &__content {
    // @media(min-width: 1200px) {
    //   margin-left: rem(50px);

    // }
    }
    &__title {
      color: $color;
      // font-weight: normal;
      &-bg {
        background-color: $accent-2;
      }
    }

    &__subtitle{
      margin-top: rem(30px);
      background-color: $accent-2;
      position: relative;
      border-radius: 5px;

      &::after {
        content: '';
      display: block;
      background-image: url(../../assets/images/gooe.svg);
      width: rem(80px);
      height: rem(20px);
      position: absolute;
      background-repeat: no-repeat;
      background-size: contain;
      right: 20%;
      bottom: rem(-20px);
      z-index: 1;
      }

    }

    &__subtitle-content {
      color: $color;
      padding: rem(10px);
      @media(min-width: $from-medium){
        text-align: left;

      }
      p {
        margin: 0 auto;
      }
    }
  }
  .scroll-text {
    color: $color;
    
  }
  .scroll-arrow svg {
    fill: $color;
  }
}

[data-namespace="crm-jobs"] {
  .c-main-hero__inner {
    padding-top: 0;
  }
}


// .page-mission-and-values {
//   .c-header-navigation__link {
//     color: #292929;

//     &:hover {
//       color: #292929;
//     }
//     &.-cta-btn {
//       color: #fff;
//     }

//     &.has-drop {
//       svg {
//         fill: red;
//       }
//     }
// }
// }


[data-namespace="crm-jobs"] {
  .c-main-hero__content {
    text-align: center;
  }
  
  


  .c-main-hero {
    height: 20vh;
    min-height: 360px;

    @media (min-width: $from-medium) {
      min-height: 30vh;
      height: 400px;
  
     
      
    }
    
  }
}