.c-education-benefits {
  position: relative;
  

  &__main {

    position: relative;

    &-content {
      @media(min-width: $from-small) {
        
        margin-top: -5rem;
      }
      position: relative;
      z-index: 1;
    }

    &-inner {
      padding: 3rem 0;
      @media(min-width: $from-small) {

        padding: 3rem;
      }
      background-color: #fff;
      position: relative;
      border-radius: 10px;
      z-index: 1;

  

      &::after {
        content:'';
        display: block;
        position: absolute;
        background-image: url('../../assets/images/home/hat.png');
        opacity: 0.05;
        width: rem(300px);
        height: rem(300px);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center right;
        right: 0;
        bottom: 10%;
        z-index: -1;

        
      }
    }
  };

  &__image {

      height: rem(250px);
      overflow: hidden;
      position: relative;

      @media (min-width: $from-small) {
        height: rem(500px);
      }


  }

  &__list {

    display: flex;
    justify-content: space-around;
    
    margin: rem(60px) 0;

    flex-flow: wrap;
    @media(max-width: 375px) {
flex-flow: column;
    }

    @media(min-width: $from-medium) {
      flex-flow: row;
    }

    &-item {
      display: flex;
      flex-flow: column;
      align-items: center;
      margin: 0 rem(30px);

      @media(min-width: $from-tiny) {
       width: 35%;
      }
    }

    &-icon {
      width: rem(90px);
      height: rem(180px);
      display: flex;
      justify-content: center;
    }

    &-content {
      font-weight: bold;
      text-align: center;
    }


    &-header {
      padding-top: rem(30px);
      h3 {
        font-weight: bold;
        color: $accent
      }
    }
  };
}
