.c-scrollbar {
    position: absolute;
    right: 0;
    top: 0;
    width: 11px;
    height: 100vh;
    transform-origin: center right;
    transition: transform 0.3s, opacity 0.3s;
    opacity: 0;
    z-index: 9999;

    &:hover {
        transform: scaleX(1.45);
    }

    &:hover, .has-scroll-scrolling &, .has-scroll-dragging & {
        opacity: 1;
    }
}

.c-scrollbar_thumb {
    position: absolute;
    top: 0;
    right: 0;
    background-color: $accent-2;
    opacity: 1;
    width: 7px;
    border-radius: 10px;
    margin: 2px;
    cursor: grab;

    .has-scroll-dragging & {
        cursor: grabbing;
    }
}


.progress {
    background: linear-gradient(to right, $accent var(--scroll), transparent 0);
    background-repeat: no-repeat;
    position: fixed;
    width: 100%;
    height: 4px;
    z-index: 99999;
  }
  