.c-footer {
  position: relative;
  height: auto;
  margin-top: rem(90px);
  padding-top: 1rem;

  @media (min-width: $from-medium) {
    padding-top: 0;
  }

  &__content {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    height: 6rem;
    color: $color;
    z-index: 1;

    &::before {
      content: "";
      opacity: 0.5;
      position: absolute;
      right: 0;
      left: 0;
      bottom: 0;
      background-color: $border-line;
      height: 1px;
      width: calc(100% - 18rem);
      margin: 0 auto;
    }

    @media (min-width: $from-medium) {
      height: 9rem;
    }
  }



  &__link-info {
    color: $color;
    display: flex;
    justify-content: space-between;
    flex-flow: column;

    @media(min-width:$from-small) {
      flex-flow: row;
    }

    span {
      font-size: 0.9375rem;
      font-weight: bold;
    }

    p {
      margin: 0 auto;
      font-size: 0.9375rem;
    }

    a {
      color: $color;
      &:hover {
        color: $accent
      }
    }

    >div {
      padding: 1.3rem 0;
    }
  }

  &__info {
    position: relative;
    height: 100%;
    display: block;
    padding: 2rem 0;

    @media (min-width: $to-medium) {
      padding: 0 9rem;
    }

    &-layout {
      padding: 0rem;

      @media (max-width: $to-tiny) {
        flex-flow: column;
        padding-bottom: rem(30px);
      }

      @media (min-width: $from-small) {
        padding: 0 1rem;
      }

      @media (min-width: $from-medium) {
        padding: 1rem 0rem;
      }

      // .o-layout__item {
      //   @media (min-width: $from-medium) {
      //     width: 80%;
      //   }
      // }
    }

    .o-container-fluid {
     
      @media(max-width: $to-medium) {
        padding:0 9rem ;
      }

      @media(max-width: $to-small) {
        padding: 0 7vw;
      }
    }
  }

  &__media {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: rem(20px) 0;
    flex-flow: column;

    @media(min-width:$from-medium) {
      flex-flow: row;
    }

  
  }

  &__social {
    display: flex;
    align-items: center;
    


    @media (max-width: $to-medium) {
      padding: 2rem 0;
      justify-content: center;
    }

    &__item {
      margin-left: 1rem;

      @include first-child() {
        margin-left: 0;
      }

     
    }

    &-link {
      display: flex;
      align-items: center;
      justify-content: center;
      
      position: relative;
      text-align: center;
      padding: 0.5rem;
      width: 3rem;
      
      height: 3rem;

      &.-twitter {
        &:hover {
          svg {
            fill: $twitter-color;
          }
        }
      }

      &.-linkedin {
        &:hover {
          svg {
            fill: $linkedin-color;
          }
        }
      }

      &.-facebook {
        &:hover {
          svg {
            fill: $facebook-color;
          }
        }
      }

      &.-snap {
        &:hover {
          svg {
            fill: $snap-color;
          }
        }
      }

      &.-youtube {
        &:hover {
          svg {
            fill: $youtube-color;
          }
        }
      }

      &.-instagram {
        &:hover {
          svg {
            fill: $instagram-color;
          }
        }
      }
    }

    &-icon {
      width: 0.88rem;
      height: 0.88rem;
      fill: $color;
      transition: 0.3s all ease-in-out;
    }
  }

  &__eeo {
    color: $color;
    margin: 2rem 0;
    font-size: 12px;

    p {
      margin-bottom: rem(10px);
    }

    a {
      color: $accent;
    }
  }

  &__copy-right {
    position: relative;
    color: $color;
    padding: 2rem 0;
    font-size: 14px;

    &:before {
      content: "";
      opacity: 0.5;
      position: absolute;
      right: 0;
      left: 0;
      top: 0;
      background-color: $border-line;
      height: 1px;
      width: calc(100%);
      margin: 0 auto;
    }

    p {
      font-size: 14px;
    }

    ul {
      @media(min-width: $from-medium) {
        display: flex;

      }
      font-size: 14px;
      justify-content: space-between;
    }

    &-layout {
      padding: 0rem;

      @media (min-width: $from-small) {
        padding: 0 1rem;
      }

      @media (min-width: $from-medium) {
        padding: 0;
      }
    }

    a {
      color: $accent-3;
    }

    &--reserved {
      display: flex;
      align-items: center;
      margin-top: rem(30px);
      
@media(min-width: $from-medium) {
  text-align: right;
  justify-content: right;
  margin-top: 0;
}
      svg,img {
        width: rem(30px);
        height: rem(30px);
        margin-right: rem(10px);
      }
      
    }
  }

  &__license {
    @media (min-width: $from-medium) {
      width: 75%;
    }
  }

  &__site-by {
    @media (min-width: $from-medium) {
      width: 25%;
    }
  }

  &__external-links {
    display: flex;
    flex-flow: column;
    @media(min-width:$from-small) {
      flex-flow: row;
    }
    a {
      
      margin-bottom: rem(30px);
      color: $color;

    

    
      @media(min-width:$from-small) {
        margin: 0 rem(5px);

 

      }

      img {
        width: 118px;
      }
    }
   
    .o-button {
     border-radius: 5px; 
     height: 37px;

     @media(min-width:$from-small) {
       
       

     }

     &__inner {
       height: 100%;
     }

     &__icon {
       width: rem(25px);
       height: rem(25px);
       
     }

     &__label {
       font-size: rem(12px);
     }
    }

    .double {



      @media(max-width: $to-small) {
        display: flex;
        justify-content: space-between;
      }
      a {

        @media(max-width: $to-small) {
          @include first-child {
            margin-right: rem(10px);
          }
        }

        
      }

      @media(min-width: $from-medium) {
        display: flex;
        &:before {
          content: '';
          display: inline-block;
          height: 100%;
          width: 1px;
          background: rgba(182, 184, 185, 0.29);
          position: relative;
          margin: 0 1rem;
        }
      }
    }

  }
}

.__back-to {
  display: none;

  @media (min-width: $from-medium) {
    opacity: 0;
    top: -40%;
    right: 0;
    bottom: 0;
    width: 9rem;
    height: 20rem;
    position: absolute;
    display: block;
    cursor: pointer;

    .__back-to-link {
      width: 100%;
      height: 9rem;
      position: absolute;
      bottom: 5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: rotate(90deg);

      &:hover {
        .__back-to-icon {
          transform: translateX(-15px) scale(0.9);

          svg {
            fill: $accent;
          }
        }

        .__back-to-label {
          opacity: 0.7;
          transform: translateX(-10px);
        }
      }
    }

    .__back-to-icon {
      position: relative;
      pointer-events: none;
      transition: transform 1.2s cubic-bezier(0.77, 0, 0.175, 1),
        opacity 0.3s ease-in-out;
      width: 25px;
      height: 43px;
      left: -1.3rem;

      svg {
        fill: $color;
        width: 25px;
        height: 43px;
        transition: 0.3s all ease-in-out;
        transform: rotate(90deg);
      }
    }

    .__back-to-label {
      color: $color;
      pointer-events: none;
      font-size: 12px;
      margin: 1px 0 0;
      white-space: nowrap;
      line-height: 43px;
      transition: transform 1.2s cubic-bezier(0.77, 0, 0.175, 1),
        opacity 0.3s ease-in-out;
    }
  }
}

.c-language {
  position: fixed;
    bottom: 0;
    z-index: 9999;
    padding: rem(10px);
    background-color: $accent;
    color: #fff;
    border-radius:0 5px 0 0 ;
    font-size: rem(12px);

    a {
      color: #fff;
      font-weight: bold;

      &.selected {
        color: $accent-2;
      }
    }
}