.u-anime {
  .is-loaded & {
    opacity: 1;
    transition: 0.6s;
    transform: translateY(0);
    &.-delay-1 {
      transition-delay: 0.15s;
    }
    &.-delay-2 {
      transition-delay: 0.3s;
    }
    &.-delay-3 {
      transition-delay: 0.45s;
    }
    &.-delay-4 {
      transition-delay: 0.6s;
    }
    &.-delay-5 {
      transition-delay: 1.15s;
    }
  }
  transform: translateY(-190px);
  transition: opacity 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s,
    transform 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s;
  opacity: 0;
}
