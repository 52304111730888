.o-image_wrapper {
  position: relative;
  overflow: hidden;
  background-color: hsl(0,11%, 81%);

}

.o-image {
  opacity: 0.75;
  mix-blend-mode: multiply;

  img {
      width: 100%;
      opacity: 0;
      transform: scale(1.4);
      transition: opacity 1.2s $Power2EaseOut, transform 1.2s $Power2EaseOut;
  }

  &.is-inview {
      img {
          opacity: 1;
          transform: scale(1);
          transition-delay: 0.6s;
      }
  }
}
