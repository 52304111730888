// ==========================================================================
// Base / Fonts
// ==========================================================================

@include font-face(
  $font-icon,
  "fonts/custom-icons/custom-icons",
  ("eot", "woff2", "woff", "ttf")
) {
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}



@include font-face(
  $font-speedee,
  "fonts/Speedee/Speedee-Bold",
  ("eot","woff2","woff", "ttf")
) {
  font-style: normal;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  font-display: swap;
}

@include font-face(
  $font-speedee,
  "fonts/Speedee/Speedee-Italic",
  ("eot","woff2","woff", "ttf")
) {
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
  font-display: swap;
}

@include font-face(
  $font-speedee,
  "fonts/Speedee/Speedee-Regular",
  ("eot","woff2","woff", "ttf")
) {
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
  font-display: swap;
}
