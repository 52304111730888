// ==========================================================================
// Objects / Container
// ==========================================================================

//
// Page-level constraining and wrapping elements.
//
// > In programming languages the word *container* is generally used for structures
// that can contain more than one element.
// > A *wrapper* instead is something that wraps around a single object to provide
// more functionalities and interfaces to it.
// @link http://stackoverflow.com/a/13202141/140357
//

/* stylelint-disable */
@if (type-of($container-width) != number) {
    @error "`#{$container-width}` needs to be a number.";
  }
  /* stylelint-enable */
  
  .o-container {
    margin-right: auto;
    margin-left: auto;
    padding-right: 7vw;
    padding-left: 7vw;
    max-width: $container-width;
    @media (min-width: $from-tiny) {
      padding-right: 10vw;
      padding-left: 10vw;
    }
  
    @media (min-width: $from-small) {
      padding-right: $padding;
      padding-left: $padding;
    }
  }
  