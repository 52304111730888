// ==========================================================================
// Base / Page
// ==========================================================================

//
// Simple page-level setup.
//
// 1. Set the default `font-size` and `line-height` for the entire project,
//    sourced from our default variables.
// 2. Ensure the page always fills at least the entire height of the viewport.
//

html {
    min-height: 100%;
    /* [2] */
    color: $color;
    font-family: $font-family;
    line-height: $line-height;
    /* [1] */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @media (max-width: $to-small) {
        font-size: $font-size - 2px;
    }

    @media (min-width: $from-small) and (max-width: $to-medium) {
        font-size: $font-size - 2px;
    }

    @media (min-width: $from-medium) and (max-width: $to-large) {
        font-size: $font-size - 1px;
    }

    @media (min-width: $from-large) and (max-width: $to-huge) {
        font-size: $font-size;
        /* [1] */
    }

    @media (min-width: $from-huge) and (max-width: $to-gigantic) {
        font-size: $font-size + 1px;
    }

    @media (min-width: $from-gigantic) and (max-width: $to-colossal) {
        font-size: $font-size + 2px;
    }

    @media (min-width: $from-colossal) {
        font-size: $font-size + 4px;
    }

    &.is-loading {
        cursor: wait;
    }

    &.has-scroll-smooth {

        // backface-visibility: hidden;
        // transform: translateZ(0);

        overflow: hidden;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }

    &.has-scroll-dragging {
        user-select: none;
    }
}

body {
    overflow-x: hidden;
    .has-scroll-smooth & {
        overflow: hidden;
    }
}

::selection {
    background-color: $selection-background-color;
    color: $selection-text-color;
    text-shadow: none;
}

a {
    color: $link-color;

    @include u-hocus {
        color: $link-hover-color;
    }
}


[data-scroll-container] {
    .has-scroll-smooth & {
        min-height: 100vh;
    }

    [data-scroll-direction="horizontal"] & {
        white-space: nowrap;
        height: 100vh;
        display: inline-block;
        white-space: nowrap;
    }
}

[data-scroll-section] {
    [data-scroll-direction="horizontal"] & {
        display: inline-block;
        vertical-align: top;
        white-space: nowrap;
        height: 100%;
    }
}

#svg-- {
    display: none;
}



// html:not(.has-scroll-init){
//     .c-header-navigation {
//       position: relative;
//       background: #292929;
//     }
//     .c-header-navigation__content {
//       background-color: transparent;
//     }
//   }

#job-detail,
[data-page-name="Find jobs by location"],
#location-description,
.page-jobs,
.page-corporate-search,
.page-restaurant-jobs {
    .c-header-navigation {
        position: relative;
        background: #292929;
    }
}

.o-wrap {
    overflow-x: hidden;
}


main {
    height: 100%;
}

.mt-large {
    margin-top: rem(180px);
}