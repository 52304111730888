[data-component="mission"] {
  position: relative;
  z-index: 1;
}
.c-mission {
  position: relative;
  margin: rem(50px) 0;
  @media(min-width: $from-medium) {
    margin: rem(160px) 0;

  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    bottom: -10rem;
    left: 0;
    background-image: url('../../assets/images/canada-logo.png');
    width: rem(250px);
    height: rem(250px);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: -50px bottom;
    opacity: 0.4;
  }

  .c-bubbles {
    position: absolute;
    right: 0;
    top: rem(-300px);
    width:  rem(400px);
    height: rem(400px);
    will-change: transform;
    svg {
      width: 100%;
      height: 100%;
    }
  }

  h3 {
    font-size: rem(31px);
  }

  &__grid-list {
    position: relative;
    display: flex;
  }

  &__grid-item {
    position: relative;
    width: 100%;

    @include middle(3) {
      margin: 0 rem(60px);

      @media(min-width: $from-medium) {
        margin: 0 rem(80px);
      }

      @media(max-width: $to-small) {
        margin: 0;

        .c-mission__grid-image {
          border-radius: 0;
        }
      }


      &::before {

        content: '';
        display: block;
        background-color: $accent;
        position: absolute;
        width: rem(150px);
        height: rem(150px);
        top: rem(-30px);
        right: rem(-30px);
        border-radius: 5px;
      }
    }

    @media(max-width: $to-medium) {
      [data-page="overview"] & {
        display: none;

      }
    }

    @include first-child() {

      @media(max-width: $to-medium) {
        display: none;
      }

      &::before {

        content: '';
        display: block;
        background-color: $accent;
        position: absolute;
        width: rem(150px);
        height: rem(150px);
        bottom: rem(-30px);
        right: rem(-30px);
        border-radius: 5px;
      }

      .c-mission__grid-image {
        
        border-radius: 0 10px 10px 0;
      }
    }

    @include last-child() {

      @media(max-width: $to-medium) {
        display: none;
      }

      &::before {

        content: '';
        display: block;
        background-color: $accent;
        position: absolute;
        width: rem(150px);
        height: rem(30px);
        bottom: rem(-30px);
        right: rem(-30px);
        border-radius: 0 0 0 10px;
        transition: 0.3s all ease-in-out;
      }

      .c-mission__grid-image {
        border-radius: 10px 0 0 10px;
      }
    }
  }

  &__grid-image {
    width: 100%;
    height: rem(250px);
    position: relative;
    border-radius: 10px;
    overflow: hidden;
  }
}



.c-values {

  
  position: relative;
  padding-top: rem(200px);

  @media(max-width: $to-small) {
    padding-top: 0;
  }



  &::before {
    content: '';
    top: rem(-60px);
    z-index: -1;
    right: 0;
    display: block;
    width: 100%;
    position: absolute;
    height: 100%;
    background-image: url(../../assets/images/values/long.svg);
    background-size: cover;

    @media(min-width: $from-enormous) {
      background-size: contain;
  background-repeat: no-repeat;
  background-position: right -100px;
    }
  }

  &__banner {

    display: block;
    background-color: $accent-3;
    padding: rem(30px);
    position: relative;

    @media(min-width: $from-medium) {
      border-radius: 5px 0 0 5px;
      right: -9rem;
      width: calc(100% - 9rem);

      &-img {
        svg {
          width: rem(300px);
          height: rem(300px);
        }
      }

    }

    .o-container {
      padding: 0;

      @media(min-width: $from-small) {
        padding: 0 rem(120px);

      }
    }


    &-inner {
      display: flex;
      align-items: center;
      flex-flow: column;

      @media(min-width: $from-medium) {
        flex-flow: row;
      }
    }

    &-img {
      @media(max-width: $to-medium) {
        margin-bottom: rem(30px);
      }
    }

    &-content {
      @media(min-width: $from-medium) {
        margin-left: rem(90px);
      }
    }
  }

  &__list {
    &-item {
      display: flex;
      flex-flow: column;
      align-items: center;
      padding: rem(60px) 0;
      justify-content: flex-end;

      @media(min-width: $from-medium) {
        padding: rem(120px) 0;
        flex-flow: row;
      }

      @include last-child() {
        padding-bottom: rem(190px);
        @media(max-width: $to-small) {
          padding-bottom: rem(60px);
        }
      }
    }

    &-image {
      margin-bottom: rem(60px);
      will-change: transform;

      @media(min-width: $from-medium) {
        margin-bottom: 0;
        margin-right: rem(90px);
        // width: 40%;

        width: 25%;
    position: absolute;
    left: 9rem;

      }

      img {
        display: block;

        height: rem(300px);

        @media(min-width: $from-medium) {
          height: auto;
          transform: scale(0);
          transition: transform .6s cubic-bezier(.17,.67,.3,1.33);
  
          &.is-inview {
            transform: scale(1);
      transition-delay: .3s;
  
  
          }

        }
      }
    }

    &-content {
      width: 100%;

      @media(min-width: $from-medium) {
        width: 60%;

      }

      &--header {
        font-weight: bold;
        opacity: 1;
        color: $accent-2;
        font-size: rem(60px);
        text-transform: uppercase;

        @media(min-width: $from-medium) {
          // color: $color;
          
          font-size: rem(100px);
          // text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;

          color: white;
          text-shadow: 1px 1px black, -1px -1px black;

          @supports(-webkit-text-stroke: 1px black) {

            color: transparent;
            -webkit-text-stroke: 1px black;
            text-shadow: none;

          }
        }
      }
    }
  }

  .c-image-info {
    margin-top: 0;

    @media(max-width: $to-medium) {
      margin-bottom:0;
      border-bottom: 5px solid #db0006;
    }
    
  }

  .c-image__background-wrap.-video,
  .c-image__background-wrap.-small {
    width: 100%;
  }

  .c-image-info__content-logo {
    margin-bottom: rem(30px);
  }
}